.img-fit-none {
  object-fit: none;
}

img {
  object-fit: cover;
}

p {
  font-family: "Poppins" , "Vazirmatn" !important;
}

.main-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mat-mdc-fab {
  position: fixed !important;
  bottom: 45px;
  right: 15px;
}

.mat-mdc-fab:lang(ar) {
  left: 15px;
  right: 0;
}

.btn-height {
  height: 48px !important;
}

mat-form-field mat-progress-spinner {
  margin-inline-end: .5rem;
}

.bold-text {
  font-weight: 600 !important;
}

.center-text {
  text-align: center !important;
}

.flex {
  display: flex !important;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.primary-color {
  color: var(--primary) !important;
}

.full-width {
  width: 100% !important;
}

.error-hight {
  min-height: 22px;
  height: 22px;
}

.primary-btn {
  color: #ffffff !important;
  background-color: var(--primary) !important;
}

.pointer {
  cursor: pointer !important;
}

.error-color {
  color: var(--error-lable-color);
}

.title-font {
  font-size: 18px;
}

.m0 {
  margin: 0px !important;
}

.mb-4 {
  margin-bottom: 9px !important;
}

.left {
  left: 0 !important;
}

.right {
  right: 0 !important;
}

.bottom-center {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.half-width{
  width: 50% !important;
}

.hide {
  display: none !important;
}

.mt-0{
  margin-top: 0px !important;
}