* {
  letter-spacing: 0.5px;
}

.very-large {
  font-size: 2rem;
  font-weight: 600;
}

.large {
  font-size: 1.5rem;
  font-weight: 600;
}

.medium {
  font-size: 1.2rem;
  font-weight: 500;
}

.normal {
  font-size: 1rem;
  font-weight: 400;
}

.small {
  font-size: .8rem;
  font-weight: 400;
}