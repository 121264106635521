:root {
  --primary: #00796B;
  --background: #fafafa;
  --light-background: #E5F2E8;
  --text-color: #272727;
  --cus-background: #E5F2E8;
  scrollbar-width: none;
  --contrasting-background:#ffffff;
  --error-lable-color : #ff5722;
  --btn-background : #E5F2E8;
  --success: #00796B;
  --success-light: #E5F2E8;
  --warning: #c4c101;
  --warning-light: #fffedc;
  --danger: #d20000;
  --danger-light: #FEE2E2;
  --inactive-status: #991B1B;
  --blue:#007FFB ;
  --blue-light: #CDE6FF;
  --table-background: #ffffff ;
  --border-color: rgb(0 0 0 / 12%);
  --table-footer : #f2f2f2;
  --input-form : #E5F2E8 ;
  --upcoming : #BAD9F8;
  --confirmed: #E5F2E8;
  --missed : #fff0c0 ;
  --canceled: #FEE2E2 ;
  --upcoming-border: #007FFB;
  --missed-border : #FFC300;
  --calender-border: #f7f7f7;
  --calender-branch: #f7f7f7;
  --calender-minutes: rgb(165 165 165 / 6%) ;
  --calender-doctor : #00796B;
  --browse-btn : #d1fae5;
  --browse-btn-text: #00796B ;
  --ngx-file-drop-border : #00796B;
  --fifty-min-line :  rgb(0 0 0 / 3%) ;
}

.primary {
  color: var(--primary);
}

.lightgray {
  color: lightgray;
}

.darkgrey {
  color: darkgrey;
}

.danger {
  color: red;
}
