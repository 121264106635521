* {
  scrollbar-color: lightgray transparent;
  scrollbar-width: thin;
}

html,
body {
  scroll-behavior: smooth;
}


:host {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  scrollbar-width: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #979797;
  border-radius: 6px;
  resize: both;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979797;
}
