:root .dark-theme {
  --primary: #29998C;
  --background: #2E2E2E;
  --light-background: #373737;
  --cus-background: #29998C;
  --text-color: #fafafa;
  scrollbar-width: none;
  --contrasting-background:#353535;
  --error-lable-color : #ff5722;
  --btn-background : #2E5F59;
  --success: #29998C;
  --success-light: #E5F2E8;
  --warning: #c4c101;
  --warning-light: #fffedc;
  --danger: #d20000;
  --danger-light: #FEE2E2;
  --inactive-status: #991B1B;
  --blue:#4682B4 ;
  --blue-light: #CDE6FF;
  --table-background: #353535 ;
  --border-color: rgba(255 255 255 / 12%) ;
  --table-footer : #373737 ;
  --input-form : #444444 ;
  --upcoming : #3F6280;
  --confirmed: #2E6660;
  --missed : #876C29 ;
  --canceled: #A15959 ;
  --upcoming-border: #4682B4;
  --missed-border : #DAA520;
  --calender-border: #E4E4E4;
  --calender-branch: #444444;
  --calender-minutes: #E4E4E4 ;
  --calender-doctor : #ffffff;
  --browse-btn : #29998C ;
  --browse-btn-text: #ffffff ;
  --ngx-file-drop-border : #767676;
  --fifty-min-line: rgba(255 255 255 / 5%) ;
  
}



.dark-theme {
  .active-list-item {
    background-color:#00796B  !important;
  }



}