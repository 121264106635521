:root {
  --ngx-file-drop-width: 350px;
  --ngx-file-drop-height: 200px;
  --ngx-file-drop-border-radius: 30px;
}

.file-drop {

  .ngx-file-drop__drop-zone,
  .ngx-file-drop__content {
   // height: var(--ngx-file-drop-height) !important;
    color: var(--browse-btn-text) !important;
  }

  .ngx-file-drop__drop-zone {
    border: 2px dotted var(--ngx-file-drop-border) !important;
  }

  img {
    width: var(--ngx-file-drop-width);
    height: var(--ngx-file-drop-height);
    border-radius: var(--ngx-file-drop-border-radius);
  }
}

.ngx-file-drop-selected-files {
  margin-top: 1rem;

  .ngx-file-drop-preview-file {
    padding: .5rem 0;
  }

  img,
  .icon {
    width: 65px;
    height: 45px;
    border-radius: 1rem;
    object-fit: cover;
  }
}

.ngx-file-drop-selected-file {
  position: relative;
  width: fit-content;
  // max-width: var(--ngx-file-drop-width);

  // .ngx-file-drop-selected-img {
  //   height: 200px;
  //   border-radius: var(--ngx-file-drop-border-radius);
  //   object-fit: contain;
  // }

  .clear {
    // position: absolute;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #2d2d2d75;
    cursor: pointer;
    right: -0.4rem;
    top: -0.4rem;
    z-index: 10;

    mat-icon {
      color: white;
      margin: auto;
    }
  }
}
